import { createRef, RefObject, useEffect, useRef, useState } from 'react';
import { LocationHook, useLocation } from 'wouter';
import { Helmet } from 'react-helmet';
import css from './WorkModal.module.scss';
import data, { IMedia, IProject } from '../../resources/projects';
import config from '../../config/config';
import cn from 'classnames';

export default ({ region }: { region: string }) => {
	const [, setLocation] = useLocation<LocationHook>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [closing, setClosing] = useState<boolean>(false);
	const [showOverlay, setShowOverlay] = useState<boolean>(false);
	const projectsContainerRef: RefObject<HTMLDivElement> = useRef(null);
	const projectsRef: RefObject<any> = useRef(data.map(() => createRef()));
	const anchorsRef: RefObject<any> = useRef(data.map(() => createRef()));

	useEffect(() => {
		setTimeout(() => {
			setLoaded(true);
		}, 1600);

		setTimeout(() => {
			setShowOverlay(true);
			setTimeout(() => setShowOverlay(false), 2000);
		}, 1000);
	}, []);

	function onClose() {
		setClosing(true);
		setTimeout(() => {
			setLocation('/');
		}, 500);
	}

	return (
		<div
			className={cn(css.container, closing && css.closingModal)}
			onClick={onClose}>
			<Helmet>
				<link rel='canonical' href={`${config.homeUrl}/work/`} />
			</Helmet>
			<h1>My Work</h1>
			<img
				alt='close'
				className={css.x}
				onClick={onClose}
				src='/assets/x-white.svg'
			/>
			<div className={cn(css.modal)} onClick={(e) => e.stopPropagation()}>
				{loaded && (
					<>
						<div
							className={cn(
								css.overlay,
								!showOverlay && css.hideOverlay,
							)}>
							<div className={css.prompt}>
								Scroll down to browse projects
								<img
									alt='arrow-down'
									src='/assets/arrow-right.svg'
								/>
							</div>
						</div>

						<div
							ref={projectsContainerRef}
							className={css.projects}>
							{data.map((project: IProject, index: number) => {
								if (
									project.exclude?.includes(region) &&
									region
								) {
									return null;
								}
								return (
									<>
										<div
											className={cn(
												css.project,
												index % 2 === 1 && css.alt,
											)}
											ref={anchorsRef.current[index]}>
											<div className={css.info}>
												<div className={css.logo}>
													{project.title}
													{/* <img
													alt='project-logo'
													style={{
														height:
															project.logo_height ||
															50,
													}}
													src={project.logo}
												/> */}
												</div>
												<div className={css.field}>
													<b>Role</b> {project.role}
												</div>
												<div className={css.field}>
													<b>Tech</b>
													{project.skills.map(
														(skill: string) => (
															<div
																className={
																	css.skill
																}>
																{skill}
															</div>
														),
													)}
												</div>
												{project.description && (
													<div className={css.field}>
														<b>About</b>
														{project.description}
													</div>
												)}
												<div className={css.field}>
													<b>YEAR(S)</b>
													{project.start_year}
													{project.end_year
														? ` - ${project.end_year}`
														: ''}
												</div>
											</div>
											<div className={css.media}>
												{project.media.map(
													(
														media: IMedia,
														imageIndex: number,
													) =>
														media.src.includes(
															'webm',
														) ? (
															<video
																className={
																	css.video
																}
																autoPlay
																muted
																loop>
																<source
																	src={`/projects/videos/${media.src}`}
																/>
															</video>
														) : (
															<img
																alt={`project-${index}-${imageIndex}`}
																className={
																	css.image
																}
																src={`/projects/images/${media.src}`}
															/>
														),
												)}
											</div>
										</div>
										{index < data.length - 1 && (
											<div
												ref={projectsRef.current[index]}
												className={css.nextProject}
											/>
										)}
									</>
								);
							})}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
