import TransitionContent from '../TransitionContent/TransitionContent';
import { ERoute } from '../../global';
import { projectImages } from '../../resources/projects';
import Preloader from '../Preloader/Preloader';
import { LocationHook, useLocation } from 'wouter';
import { useEffect, useState } from 'react';
import WorkModal from '../WorkModal/WorkModal';
import Mask from '../Mask/Mask';

export default () => {
	const [location] = useLocation<LocationHook>();
	const [region, setRegion] = useState<string>('');
	const [assetsLoaded, setAssetsLoaded] = useState<boolean>();
	const showPage: boolean = location === ERoute.Work;

	useEffect(() => {
		getRegion();
	}, []);

	async function getRegion() {
		try {
			const res = await fetch(
				'https://api.geoapify.com/v1/ipinfo?apiKey=4e46cc968f46450bada75a3c7b7e7d62',
			);
			const json = await res.json();
			if (json.continent) {
				setRegion(json.continent.code.toLowerCase());
			} else {
				setRegion('none');
			}
		} catch (err) {
			setRegion('none');
		}
	}

	function renderContent() {
		return (
			<Mask show={showPage}>
				<>
					{!assetsLoaded && (
						<Preloader
							numbers
							onLoaded={() => setAssetsLoaded(true)}
							assets={projectImages}
						/>
					)}
				</>
			</Mask>
		);
	}

	return (
		<>
			<TransitionContent
				show={showPage}
				route={ERoute.Work}
				h1='My Work'
				titleLine1='MY'
				titleLine2='WORK'
				infoContent={<></>}
				content={renderContent()}
			/>
			{assetsLoaded && region && showPage && (
				<WorkModal region={region} />
			)}
		</>
	);
};
