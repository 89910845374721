export default [
	'portrait.jpg',
	'arrow-left.svg',
	'arrow-right.svg',
	'click-hand.png',
	'error.svg',
	'github.svg',
	'loading.svg',
	'mail.svg',
	'linkedin.svg',
	'phone.svg',
	'cv.svg',
	'mouse-pointer.svg',
	'x.svg',
	'x-white.svg',
	'bg-texture.jpg',
];
